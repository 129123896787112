import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import { formatTitle } from '../../utils/shop';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import ShopProductCategory from '../../components/Shop/ShopProductCategory';

const CATEGORIES = ['iBooks', 'Other', 'READ', 'WATCH', 'WEAR'];

const addURLToProductListItems = (categoryItems = []) => {
  return categoryItems.map((item) => {
    return { ...item, url: formatTitle(item.title) };
  });
};

const CategoryPageComponent = (props) => {
  const {
    data: { bgShop, allShopifyProducts },
    pageContext: { categoryName },
    location: { href },
  } = props;

  const categoryItems = allShopifyProducts?.edges.map((elm) => elm.node);
  const categoryItemsWithURL = addURLToProductListItems(categoryItems);
  const [category, setCategory] = useState(categoryItemsWithURL);

  const seoData = {
    canonical: href || '',
    title: categoryName || '',
    metaDesc: `Shop for ${categoryName} items`,
    opengraphDescription: `Shop for ${categoryName} items`,
    twitterDescription: `Shop for ${categoryName} items`,
    opengraphTitle: categoryName || '',
    twitterTitle: categoryName || '',
    opengraphImage: {
      sourceUrl: bgShop?.childImageSharp?.fluid?.src || '',
    },
    twitterImage: {
      sourceUrl: bgShop?.childImageSharp?.fluid?.src || '',
    },
    opengraphUrl: href || '',
  };

  return (
    <BodyClassName className="body-light page-shop page-shop-cat">
      <DefaultLayoutComponent seo={seoData}>
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={bgShop?.childImageSharp?.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="pd-x-md pb-container">
                <h3 className="pb-title">Shop</h3>
              </div>
            </div>
          </BackgroundImage>
          <section className="category-container over_lpd-container bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <section className="section bg-light pd-t-0 shop">
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="breadcrumb pd-x-sm"></div>
                  <ShopProductCategory
                    category={category}
                    hideLink
                    showAll
                    showCategorySelectBox
                    slug={categoryName}
                    categories={CATEGORIES}
                  />
                </div>
              </section>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query ShopCategoryQuery($categoryName: String!) {
    bgShop: file(relativePath: { eq: "bg-shop.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allShopifyProducts: allShopifyProduct(
      filter: { tags: { eq: $categoryName } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          tags
          title
          description
          descriptionHtml
          featuredImage {
            gatsbyImageData
          }
          status
          createdAt
          priceRange: priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          metafields {
            value
            key
          }
        }
      }
    }
  }
`;

export default CategoryPageComponent;
